import FetchService from './FetchService';

/*-- Objects --*/
const fetchService = new FetchService();
/*-- /Objects --*/

/*--/Event Listeners--*/
async function submitForm(e,form){
    // prevent reloading page
    e.preventDefault();
    // submit form
    //user interaction with
    const btnSubmit = document.getElementById('btnSubmit');
    btnSubmit.disabled = true;
    
    setTimeout(()=>btnSubmit.disabled = false, 2000);
    const jsonFormData = buildJSONFormData(form);
    const header = buildHeaders(jsonFormData);
    const response = await performPostHttpRequest('index.html', header, jsonFormData);
    if(response)
        alert("Done");
    else
        alert("Error");
}

function buildJSONFormData(form){
    const jsonFromData = {};
    for(const pair of new FormData(form)){
        jsonFromData[pair.key] = pair.value;
    }
    return jsonFromData;
}


function buildHeaders(authorization = null) {
    const headers = {
        "Content-Type": "application/json",
        "Authorization": (authorization) ? authorization : "Bearer TOKEN_MISSING"
    };
    return headers;
}

async function performPostHttpRequest(server,headers,body){
    if(!server||!headers||!body){
        throw new Error("One or more POST request parameters was not passed.")
    }
    try{
        const rawResponse = await fetch(server,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
        const response = await rawResponse.json();
        return response;
    }
    catch(error){
        console.error("Error at fetch POST: ${error.message}");
        throw error;
    }
}

/*--Event Listeners--*/
const registerForm = document.querySelector("#registerForm");
if(registerForm){
    registerForm.addEventListener('submit', function(e){
        submitForm(e,registerForm);
    })
}